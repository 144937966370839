<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h1 style="margin-top: 4.25rem;">{{$store.state.bike.name}}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="color: var(--secondary-text-icon-color)">Akkustand: {{$store.state.bike.power}}%</p> 
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <h4 style="margin-top: 1.2rem;">Aktion bestätigen</h4>
        <p>Drücke den Knopf am Schloss des Fahrrads, um die Aktion zu bestätigen und deinen Standort zu verifizieren.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="flex-grow-1">
      <b-col cols="2"/>
      <b-col cols="10" class="justify-content-center align-self-center">
        <img src="@/assets/illustrations/button.png" alt="button" style="width: 100%; height: 100%; margin-top: 1.5rem; margin-bottom: 1.25rem;"/>
      </b-col>
      <b-col cols="0"/>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'BikeUnlock',
  data() {
    return {
    }
  },
  methods: {
    unlockBike() {
      // sends unlock message to the bike
      this.axios.post('/map/unlockBike', {
            bikeId: this.$store.state.bike._id
            }, {
            headers: {
              token: localStorage.getItem('token')
            }
        })
          .then((res) => {
            if (res.status === 200) {
              this.toast(`${this.$store.state.bike.name} wird entsperrt`, 'Das Bike wird vom Sever kontaktiert…', 'info');
              setTimeout(() => {
                this.$router.push('/driving');
              }, 10000);
            } else {
              this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger');
            }
          })
          .catch((err) => {
            console.error(err);
            this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger');
          });
      },
    },
  mounted() {
    // checks if the page loaded without any bike data
    if (Object.keys(this.$store.state.bike).length === 0) {
      this.$router.push('/').then(()=> this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger'));
      return;
    }
    this.unlockBike();
  }
}
</script>

<style scoped> 
</style>